
















































import {Component, Vue} from "vue-property-decorator";
import TextInput from "../../components/form/TextInput.vue";
import PasswordInput from "../../components/form/PasswordInput.vue";
import FormButton from "@/components/form/Button.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import axios from "axios";
import VueSelect from "vue-select";
import {captureException} from "@sentry/browser";

@Component({
  components: {
    TextInput,
    PasswordInput,
    VueSelect,
    FormButton,
    CheckboxInput,
  },
})
export default class Signup extends Vue {
  public name: string = "";
  public nameWarning = false;

  public email: string = "";
  public emailWarning = false;

  public city: string = "";
  public cityWarning = false;

  public country: string = "";
  public countryWarning = false;

  public institute: string = "";
  public instituteWarning = false;

  public password: string = "";
  public passwordWarning = false;
  public passwordLengthWarning = false;

  public confirmationPassword: string = "";
  public confirmationPasswordWarning = false;

  public privacyPolicyAgreement = false;
  public privacyPolicyWarning = false;

  public emailAgreement = false;

  public privacyPolicyUrl = process.env.VUE_APP_WEBSITE_URL + "/app/privacy-policy";

  public emailExists = false;

  public countries: Array<{ id: string, name: string }> = [];

  public created() {
    axios.get("/countries").then((res) => {
      this.countries = res.data;
    });
  }

  public signup() {
    if (this.validate()) {
      const data = {
        name: this.name.trim(),
        email: this.email.trim(),
        city: this.city.trim(),
        country: this.country.trim(),
        institute: this.institute.trim(),
        password: this.password.trim(),
        privacyAgreement: this.privacyPolicyAgreement,
        emailUpdateAgreement: this.emailAgreement,
      };
      axios.post("/auth/signup", data).then((res) => {
        this.$router.push("/signup/successful");
      }).catch((error) => {
        if (error.response && error.response.status == 409) {
          this.emailExists = true;
        } else {
          captureException(error);
          alert("Something went wrong");
        }
      });
    }
  }

  public validate() {
    this.nameWarning = !this.name.trim();
    this.emailWarning = !emailRegex.test(this.email);
    this.cityWarning = !this.city.trim();
    this.countryWarning = !this.country.trim();
    this.instituteWarning = !this.institute.trim();
    this.passwordWarning = !this.password.trim() || this.password.length < 10;
    this.passwordLengthWarning = this.password.length < 10;
    this.confirmationPasswordWarning = !this.confirmationPassword.trim()
      && this.password.trim() == this.confirmationPassword.trim();
    this.privacyPolicyWarning = !this.privacyPolicyAgreement;

    return !this.nameWarning && !this.emailWarning && !this.cityWarning && !this.countryWarning &&
      !this.instituteWarning && !this.passwordWarning && !this.passwordLengthWarning &&
      !this.confirmationPasswordWarning && !this.privacyPolicyWarning;
  }
}

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
