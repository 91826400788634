










import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class CheckboxInput extends Vue {
  @Prop({required: true})
  public value!: boolean;
  @Prop({default: false})
  public warning: boolean;

  public toggle() {
    this.$emit("input", !this.value);
  }

}
